<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/service-technician"
            ><a>Kartoteki</a></router-link
          >
          <router-link tag="li" to="/records/service-technician"
            ><a>Serwisanci</a></router-link
          >
          <router-link
            tag="li"
            :to="{
              name: 'ServiceTechnicianDetails',
              params: { id: this.$route.params.id },
            }"
            class="is-active"
            ><a>{{ name }}</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/records/service-technician/"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <h2 class="title level-left">{{ name }}</h2>
          </div>
          <div class="buttons level-right">
            <button v-if="technican.on_vacation" class="button is-medium is-light" style="background-color: antiquewhite;cursor:default;">
              <icon name="battery-charging"></icon><span>Serwisant na urlopie</span>
            </button>
            <button @click="userRemove" class="button is-medium is-light">
              <icon name="trash"></icon><span>Usuń</span>
            </button>
            <router-link
              tag="a"
              :to="{
                name: 'ServiceTechnicianEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-light"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
            <a
              v-if="selectATechnician"
              @click="selectTechnician"
              class="button is-medium is-dark"
              ><icon name="check"></icon><span>Wybierz</span></a
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="ym-whitebg pa40" v-if="technican">
      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="technican.firmname">
          <p class="label-small">Pełna nazwa klienta</p>
          <p class="label is-uppercase">{{ technican.firmname }}</p>
        </div>
        <div class="column is-6" v-if="technican.nip">
          <p class="label-small">NIP</p>
          <p class="label is-uppercase">{{ technican.nip }}</p>
        </div>
      </div>
      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="technican.firstname">
          <p class="label-small">Imię i nazwisko</p>
          <p class="label is-uppercase">
            {{ technican.firstname }} {{ technican.lastname }}
          </p>
        </div>
      </div>

      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="technican.address">
          <p class="label-small">Ulica</p>
          <p class="label is-uppercase">
            {{ technican.address }} <br />{{ technican.postcode }}
            {{ technican.city }}
          </p>
        </div>
        <div class="column is-6" v-if="technican.phone">
          <p class="label-small">Telefon</p>
          <p class="label is-uppercase">{{ technican.phone }}</p>
        </div>
      </div>

      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="technican.contact_person">
          <p class="label-small">Osoba kontaktowa</p>
          <p class="label is-uppercase">{{ technican.contact_person }}</p>
        </div>
        <div class="column is-6" v-if="technican.site">
          <p class="label-small">Strona www</p>
          <p class="label is-lowercase">
            <a :href="technican.site" rel="noopener" target="_blank">{{
              technican.site
            }}</a>
          </p>
        </div>
      </div>

      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="technican.code">
          <p class="label-small">Numer referencyjny</p>
          <p class="label is-uppercase">{{ technican.code }}</p>
        </div>
        <div class="column is-6" v-if="technican.email">
          <p class="label-small">E-mail</p>
          <p class="label is-lowercase">
            <a :href="'mailto:' + technican.email">{{ technican.email }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="mat30 ym-whitebg pa40" v-if="technican.on_vacation">
      <div class="column is-6">
        <p class="label-small">Termin urlopu</p>
        <div  v-for="vacation in technican.vacations" :key="vacation.id">
          <p class="label is-uppercase">
            <b style="display: block">{{ vacation.vacation_from }} - {{ vacation.vacation_to }}</b>
          </p>
          <div v-if="vacation.l4">
            <p class="label-small">L4</p>
            <p class="label is-uppercase"><b style="display: block">TAK</b></p>
          </div>
        </div>
      </div>
    </div>
    <div class="mat30 ym-whitebg pa40" v-if="order_stats.length > 0">
      <div v-for="(stats, index) in order_stats" :key="index">
        <div class="columns is-variable is-6">
          <div class="column is-6">
            <p class="label-small">Numer zlecenia</p>
            <p class="label is-uppercase">{{ stats.fullnumber }}</p>
          </div>
          <div class="column is-6">
            <p class="label-small">Data wykonania</p>
            <p class="label is-lowercase">{{ stats.repared_at }}</p>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="stats.commutes.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Dojazd</p>
            <div class="ym-table ym-table__info">
              <div
                v-for="(commute, index) in stats.commutes"
                :key="index"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div>&nbsp;</div>
                <div>
                  <b>Z: &nbsp;</b>{{ commute.city_from.toUpperCase() }}
                  <!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> -->
                </div>
                <div>
                  <b>DO: &nbsp;</b>{{ commute.city_to.toUpperCase() }}
                  <!--  <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> -->
                </div>
                <div>{{ commute.distance }} km</div>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="stats.works.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Godziny pracy</p>
            <div class="ym-table ym-table__info">
              <div
                v-for="(work, index) in stats.works"
                :key="index"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div><b>MINUTY: &nbsp;</b> {{ work.minutes }}</div>
                <div><b>GODZINY: &nbsp;</b> {{ work.hours }}</div>
                <div><b>SERWISANCI: &nbsp;</b> {{ work.workers }}</div>
                <div>{{ work.minutes }} x{{ work.workers }}</div>
                <div><b>SUMA: &nbsp;</b> {{ work.workers_hours }}</div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "ServiceTechnicianDetails",
  components: {
    FixedHeader,
  },
  data: function() {
    return {
      technican: {
        firstname: "",
        lastname: "",
      },
    };
  },
  mounted() {
    this.getUser();
  },
  computed: {
    ...mapGetters({
      selectATechnician: "orders/selectATechnician",
      order: "orders/order",
    }),
    name: function() {
      return this.technican.firstname + " " + this.technican.lastname;
    },
    order_stats: function() {
      const month = this.technican.order_stats ? Object.keys(this.technican.order_stats)[0] : [];
      return month.length > 0 ? this.technican.order_stats[month] : [];
    },
  },
  methods: {
    ...mapActions({
      getUserCall: "records/getUser",
      removeUserCall: "records/removeUser",
    }),
    getUser() {
      this.getUserCall({ id: this.$route.params.id })
        .then((resp) => (this.technican = resp))
        .catch((error) => {
          this.technican = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    userRemove() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć użytkownika?",
          content: `<span class='is-danger'>Usunięcia użytkownika nie można cofnąć.</span> Użytkownik pozostanie w utworzonych do tej pory zleceniach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeUserCall({ id: this.$route.params.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "ServiceTechnician" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    selectTechnician() {
      this.$store.dispatch("orders/setTechnician", this.technican).then(() => {
        if (this.order && this.order.id) {
          this.$router.push({
            name: "ServiceEdit",
            params: { id: parseInt(this.order.id) },
          });
        } else {
          this.$router.push({ name: "ServiceAdd" });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
